*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Plus Jakarta Sans, Arial, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

html {
  --tw-text-opacity: 1;
  color: rgb(34 34 34 / var(--tw-text-opacity));
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.btn {
  cursor: pointer;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  display: flex;
}

.btn-default {
  padding: .75rem 2.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (min-width: 1024px) {
  .btn-default {
    padding: 1rem 3rem;
  }
}

.btn-primary {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(49 67 207 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(49 67 207 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.btn-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(49 67 207 / var(--tw-text-opacity));
}

.btn-primary-inverted {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(49 67 207 / var(--tw-text-opacity));
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.btn-primary-inverted:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(49 67 207 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.form-container {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
}

.input-container {
  width: 100%;
  border-width: 1px;
  border-color: rgb(49 67 207 / var(--tw-border-opacity));
  --tw-border-opacity: .3;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: .5rem;
  align-items: center;
  gap: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: flex;
}

.input-container:focus-within, .input-container:focus {
  --tw-border-opacity: 1;
}

.form-label {
  font-weight: 600;
}

.form-input {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.form-input:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.top-0 {
  top: 0;
}

.-right-56 {
  right: -14rem;
}

.top-1\/2 {
  top: 50%;
}

.-bottom-24 {
  bottom: -6rem;
}

.-left-16 {
  left: -4rem;
}

.left-0 {
  left: 0;
}

.-top-48 {
  top: -12rem;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.-top-12 {
  top: -3rem;
}

.-left-48 {
  left: -12rem;
}

.bottom-72 {
  bottom: 18rem;
}

.-right-64 {
  right: -16rem;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-auto {
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-auto {
  margin-bottom: auto;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.h-6 {
  height: 1.5rem;
}

.h-px {
  height: 1px;
}

.h-14 {
  height: 3.5rem;
}

.h-32 {
  height: 8rem;
}

.h-full {
  height: 100%;
}

.h-8 {
  height: 2rem;
}

.h-16 {
  height: 4rem;
}

.h-auto {
  height: auto;
}

.h-1\/2 {
  height: 50%;
}

.h-\[calc\(100vh-80px\)\] {
  height: calc(100vh - 80px);
}

.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

.w-6 {
  width: 1.5rem;
}

.w-px {
  width: 1px;
}

.w-max {
  width: -webkit-max-content;
  width: max-content;
}

.w-8 {
  width: 2rem;
}

.w-\[130px\] {
  width: 130px;
}

.w-16 {
  width: 4rem;
}

.w-1\/2 {
  width: 50%;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-max {
  max-width: -webkit-max-content;
  max-width: max-content;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-\[488px\] {
  max-width: 488px;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.animate-bounce {
  animation: bounce 1s infinite;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.scroll-mt-\[88px\] {
  scroll-margin-top: 88px;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-24 {
  gap: 6rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-0 {
  gap: 0;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.border-light-gray {
  --tw-border-opacity: 1;
  border-color: rgb(240 242 246 / var(--tw-border-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-primary-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(49 67 207 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(34 34 34 / var(--tw-bg-opacity));
}

.bg-light-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(240 242 246 / var(--tw-bg-opacity));
}

.bg-sky {
  --tw-bg-opacity: 1;
  background-color: rgb(0 172 226 / var(--tw-bg-opacity));
}

.bg-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(102 102 102 / var(--tw-bg-opacity));
}

.bg-black\/50 {
  background-color: #22222280;
}

.bg-transparent {
  background-color: #0000;
}

.bg-none {
  background-image: none;
}

.object-cover {
  object-fit: cover;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-\[calc\(8rem\+88px\)\] {
  padding-top: calc(8rem + 88px);
}

.pb-12 {
  padding-bottom: 3rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-16 {
  padding-top: 4rem;
}

.text-center {
  text-align: center;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[26px\] {
  font-size: 26px;
}

.text-\[192px\] {
  font-size: 192px;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-\[40px\] {
  font-size: 40px;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-normal {
  font-weight: 400;
}

.leading-tight {
  line-height: 1.25;
}

.leading-none {
  line-height: 1;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(34 34 34 / var(--tw-text-opacity));
}

.text-primary-blue {
  --tw-text-opacity: 1;
  color: rgb(49 67 207 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-accent-red {
  --tw-text-opacity: 1;
  color: rgb(245 42 109 / var(--tw-text-opacity));
}

.text-gray {
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.opacity-50 {
  opacity: .5;
}

.opacity-80 {
  opacity: .8;
}

.opacity-30 {
  opacity: .3;
}

.opacity-5 {
  opacity: .05;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.duration-200 {
  transition-duration: .2s;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.error-message {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.group:focus-within .group-focus-within\:border-opacity-100 {
  --tw-border-opacity: 1;
}

@media (min-width: 768px) {
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media (min-width: 1024px) {
  .lg\:btn {
    cursor: pointer;
    border-radius: 9999px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    display: flex;
  }

  .lg\:btn-default {
    padding: .75rem 2.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  @media (min-width: 1024px) {
    .lg\:btn-default {
      padding: 1rem 3rem;
    }
  }

  .lg\:btn-large {
    padding: 1.25rem 3.5rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:btn-primary {
    --tw-border-opacity: 1;
    border-width: 1px;
    border-color: rgb(49 67 207 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(49 67 207 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .lg\:btn-primary:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(49 67 207 / var(--tw-text-opacity));
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:-left-32 {
    left: -8rem;
  }

  .lg\:-top-56 {
    top: -14rem;
  }

  .lg\:right-0 {
    right: 0;
  }

  .lg\:left-1\/2 {
    left: 50%;
  }

  .lg\:top-0 {
    top: 0;
  }

  .lg\:-left-56 {
    left: -14rem;
  }

  .lg\:-right-48 {
    right: -12rem;
  }

  .lg\:-bottom-12 {
    bottom: -3rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:mb-24 {
    margin-bottom: 6rem;
  }

  .lg\:mb-12 {
    margin-bottom: 3rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-8 {
    height: 2rem;
  }

  .lg\:h-80 {
    height: 20rem;
  }

  .lg\:h-12 {
    height: 3rem;
  }

  .lg\:h-24 {
    height: 6rem;
  }

  .lg\:h-52 {
    height: 13rem;
  }

  .lg\:max-h-\[340px\] {
    max-height: 340px;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-px {
    width: 1px;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-12 {
    width: 3rem;
  }

  .lg\:w-\[208px\] {
    width: 208px;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:max-w-3xl {
    max-width: 48rem;
  }

  .lg\:max-w-screen-sm {
    max-width: 640px;
  }

  .lg\:max-w-screen-lg {
    max-width: 1024px;
  }

  .lg\:max-w-6xl {
    max-width: 72rem;
  }

  .lg\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:rotate-0 {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scroll-mt-\[155px\] {
    scroll-margin-top: 155px;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:items-stretch {
    align-items: stretch;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:gap-0 {
    gap: 0;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:gap-16 {
    gap: 4rem;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .lg\:py-\[72px\] {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:pt-\[calc\(12rem\+120px\)\] {
    padding-top: calc(12rem + 120px);
  }

  .lg\:pb-12 {
    padding-bottom: 3rem;
  }

  .lg\:pt-24 {
    padding-top: 6rem;
  }

  .lg\:pt-\[72px\] {
    padding-top: 72px;
  }

  .lg\:pt-12 {
    padding-top: 3rem;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-\[48px\] {
    font-size: 48px;
  }

  .lg\:text-\[56px\] {
    font-size: 56px;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:mini-vert {
    writing-mode: vertical-rl;
    transform: rotate(deg);
    white-space: nowrap;
    color: #222;
    align-items: center;
    gap: 8px;
    display: flex;
    overflow: visible;
  }
}

@media (min-width: 1280px) {
  .xl\:max-h-64 {
    max-height: 16rem;
  }

  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:max-w-max {
    max-width: -webkit-max-content;
    max-width: max-content;
  }

  .\32 xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
}

/*# sourceMappingURL=index.20ba0965.css.map */
